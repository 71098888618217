import React from 'react'
import Approve from '@/components/icons/cardHeader/Approve'
import MissingInfo from '@/components/icons/cardHeader/MissingInfo'
import Reject from '@/components/icons/cardHeader/Reject'

export const getHeaderActionIcons = (iconName?: string) => {
  switch (iconName) {
    case 'ban':
      return <Reject />
    case 'info-circle':
      return <MissingInfo />
    case 'check-circle':
      return <Approve />
    default:
      return null
  }
}

export const removeIdFromItems = (data: any, isTopLevel = true): any => {
  if (Array.isArray(data)) {
    return data.map((item) => {
      if (typeof item === 'object' && item !== null) {
        const newItem = removeIdFromItems(item, false)
        if (typeof newItem.id === 'number') {
          delete newItem.id
        }
        return newItem
      }
      return item
    })
  }

  if (typeof data === 'object' && data !== null) {
    const newData = { ...data }
    if (!isTopLevel && typeof newData.id === 'number') {
      delete newData.id
    }
    Object.keys(newData).forEach((key) => {
      newData[key] = removeIdFromItems(newData[key], false)
    })
    return newData
  }

  return data
}
