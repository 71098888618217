import styled from 'styled-components'

import { TEXT_SIZE_STYLES, TEXT_WEIGHT_STYLES } from '@/constants/styles/typography/text'

interface FloatTextareaContainerProps {
    hasError?: boolean
}
interface TextareaProps {
    hasValue?: boolean
}

export const TextareaSC = styled.textarea<TextareaProps>`
    width: 100%;
    max-width: 100%;
    min-height: 200px;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.gray['200']};
    border-radius: 8px;
    padding: 8px 14px;
    color: ${({ theme }) => theme.colors.gray['900']};
    background: ${({ theme }) => theme.colors.base.white};
    transition: all 100ms linear 0s;
    -webkit-appearance: none;
    ${TEXT_WEIGHT_STYLES.medium};
    ${TEXT_SIZE_STYLES.sm};

    &::placeholder {
        color: ${({ theme }) => theme.colors.gray['400']};
        ${TEXT_SIZE_STYLES.sm}
        ${TEXT_WEIGHT_STYLES.medium};
    }
    &:focus {
        outline: none;
        border-color: ${({ theme }) => theme.colors.primary['300']};
        box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.colors.primary['100']};
    }
    &:hover {
        border-color: ${({ theme }) => theme.colors.primary['300']};
    }
    &:disabled {
        outline: none;
        color: ${({ theme }) => theme.colors.gray['400']};
        background: ${({ theme }) => theme.colors.gray['100']};
        border-color: ${({ theme }) => theme.colors.gray['300']};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        ${TEXT_SIZE_STYLES.sm}
        -webkit-text-fill-color: ${({ theme }) => theme.colors.gray['900']};
        -webkit-box-shadow: 0 0 0px 100px #fff inset;
    }
`

export const TextareaContainer = styled.div<FloatTextareaContainerProps>`
    width: 100%;
    height: auto;
    position: relative;

    button.icon {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
    }

    ${({ theme, hasError }) =>
        hasError &&
        `
          ${TextareaSC} {
            border: 1px solid #FDA29B;
          }
          ${TextareaSC}:focus {
            border-color: ${theme.colors.error['300']};
            box-shadow: 0px 0px 0px 2px ${theme.colors.error['100']};
          }
    `}
`
