import React from 'react'

import { Text } from '@/components/typography/Text'
import LargeFieldBlock from '@/components/blocks/LargeFieldBlock'
import { TextareaContainer, TextareaSC } from './styles'

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string
  error?: string
  info?: string
}

const Textarea: React.FC<TextareaProps> = ({ className, label, placeholder, error, info, value, ...props }) => (
  <LargeFieldBlock className={['textarea', className].join(' ')}>
    {label && (
      <Text size='xs' className='label'>
        {label}
      </Text>
    )}
    <TextareaContainer hasError={!!error}>
      <TextareaSC {...props} placeholder={placeholder} value={value} hasValue={!!value} />
    </TextareaContainer>
    {error && (
      <Text size='sm' weight='regular' className='error'>
        {error}
      </Text>
    )}
    {info && (
      <Text size='sm' weight='regular' className='info'>
        {info}
      </Text>
    )}
  </LargeFieldBlock>
)

Textarea.defaultProps = {
  label: undefined,
  error: undefined,
  info: undefined
}

export default Textarea
