import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useAlert } from '@blaumaus/react-alert'
import { isEmpty } from 'lodash'

import { Card, CardConfig, CardTitle } from '@/components/regions/Card'
import CollapsedCard from '@/components/regions/CollapsedCard'
import { Display } from '@/components/typography/Display'
import { TextPlaceholder } from '@/components/blocks/TextPlaceholder'
import Spinner from '@/components/controls/Spinner'
import { AutoSave, CardWrapper } from '@/components/regions'
import Button from '@/components/controls/Button'

import { FORM_INITIAL_VALUES_BY_MODEL_KEY } from '@/helpers/model/form/initialValues'
import { FORM_VALIDATION_SCHEMA_BY_MODEL_KEY } from '@/helpers/model/form/validation'
import { FieldConfig, SUBFORM_FIELDS_BY_MODEL_KEY } from '@/helpers/model/form/fields'
import { FilterOption } from '@/helpers/model/table/filterAndSort'
import { getPathByModelKey } from '@/helpers/model/menu'
import { getConfigByPathname, useCardsHelper } from '@/hooks/useCardsHelper'
import { useCognitoUser } from '@/hooks/useCognitoUser'

import { useAppDispatch } from '@/redux/store'
import { PageConfig } from '@/redux/actions/common/ui'
import { setFilterTableAction } from '@/redux/actions/table'
import { selectForm } from '@/redux/selectors/form'
import { selectSubformData } from '@/redux/selectors/subform'
import { updateSubformAction } from '@/redux/actions/subform'
import { setCopiedDataAction } from '@/redux/actions/copiedData'
import { selectCopiedData } from '@/redux/selectors/copiedData'
import { getFieldDataPath, getHashParams, getParentHash, splitHash } from '@/helpers/url'
import { getTitle } from '@/helpers/subforms'

import FormCardHeader from '../FormCard/components/FormCardHeader'
import SmallFields from '../FormCard/components/SmallFields'
import LargeFields from '../FormCard/components/LargeFields'
import { ButtonContainer, Fields, FormWrapper, LargeFieldsColumn, SmallFieldsColumns, StyledForm } from './styles'

export type SubformCardProps = { page: PageConfig; card: CardConfig }
const SubformCard: React.FC<SubformCardProps> = ({ page, card }) => {
  const alert = useAlert()
  const dispatch = useAppDispatch()
  const { hasWriteAccess } = useCognitoUser()
  const { onOpenForm, onOpenIndex, onClose } = useCardsHelper()
  const [isDataPasted, setIsDataPasted] = useState(false)

  const hashes = splitHash(page.path)
  const subformHash = hashes.subforms[hashes.subforms.length - 1]
  const [, modelKeyWithId] = useMemo(() => subformHash.split('/'), [subformHash])
  const [modelKey, subformId, subformNewIdentifier] = useMemo(() => modelKeyWithId.split('-'), [modelKeyWithId])
  const { id, path, model } = useMemo(() => getConfigByPathname(hashes.form), [hashes.form])

  const { data, loading, called } = useSelector(selectForm(id!))
  const subformData = useSelector(selectSubformData(getParentHash(page.path)))
  const copiedDataFromRedux = useSelector(selectCopiedData())
  const copiedDataFromLocalStorage = useMemo(() => {
    const localData = localStorage.getItem('formData')
    return localData ? JSON.parse(localData) : {}
  }, [])
  const copiedData = useMemo(
    () => (isEmpty(copiedDataFromRedux) ? copiedDataFromLocalStorage : copiedDataFromRedux),
    [copiedDataFromRedux, copiedDataFromLocalStorage]
  )
  const fieldData = useMemo(() => {
    if (subformData) {
      return subformData
    }
    if (data) {
      const fieldDataPath = getFieldDataPath(page.path)
      let subformDataInner: any = data
      fieldDataPath.forEach((fdp) => {
        if (subformDataInner) {
          const subObject: any = subformDataInner?.[fdp.fieldName]
          subformDataInner = Array.isArray(subObject)
            ? subObject.find((so, index) => index === Number(fdp.id))
            : subObject
        }
      })
      return subformDataInner
    }
    return null
  }, [data, page.path, subformData])

  const createMode = useMemo(() => subformNewIdentifier === 'new' || id === 'new', [id, subformNewIdentifier])

  const initialValues = useMemo(() => FORM_INITIAL_VALUES_BY_MODEL_KEY[modelKey], [modelKey])
  const validationSchema = useMemo(() => FORM_VALIDATION_SCHEMA_BY_MODEL_KEY[modelKey], [modelKey])
  const { large, small } = useMemo(() => SUBFORM_FIELDS_BY_MODEL_KEY[modelKey], [modelKey])
  const hasLargeFields = useMemo(() => large.length > 0, [large])
  const hashParams = useMemo(() => getHashParams(page.path), [page.path])
  const title = useMemo(() => getTitle(page.path), [page.path])

  const pageTitle = useMemo(() => {
    if (createMode) {
      return 'Create'
    }
    if (loading || !called || data === null) {
      return <TextPlaceholder />
    }
    return hashParams?.fieldName.toUpperCase()
  }, [createMode, data, loading, called, hashParams])

  useEffect(() => {
    if (called && !loading && data === null) {
      onClose(page.path)
      alert.error(`${model.title} not found`)
    }
  }, [called, loading, data, model, page.path, alert, onClose])

  const handleSubmitForm = useCallback(
    async (values: any) => {
      dispatch(
        updateSubformAction({
          key: getParentHash(page.path),
          values: {
            ...values,
            id: Number(subformId)
          }
        })
      )
    },
    [page.path, dispatch, subformId]
  )

  const handleOpenForm = useCallback(
    (formModelKey: string, formId: string, expanded: boolean) => {
      const modelPath = getPathByModelKey(formModelKey)
      onOpenForm(`${modelPath}/${formId}`, expanded, page.path)
    },
    [page.path, onOpenForm]
  )
  const handleOpenIndexTable = useCallback(
    (indexModelKey: string, filter: FilterOption) => {
      dispatch(
        setFilterTableAction({
          modelKey: indexModelKey,
          data: { key: filter.value, parameter: filter.parameterName, value: data }
        })
      )
      onOpenIndex(`${path.part}/${indexModelKey}`, page.path)
    },
    [path.part, page, data, dispatch, onOpenIndex]
  )

  const renderLargeField = useCallback(
    (config: FieldConfig) => (
      <LargeFields
        key={config?.name}
        modelKey={modelKey}
        hasWriteAccess={hasWriteAccess}
        createMode={createMode}
        config={config}
        onOpenForm={handleOpenForm}
        onOpenIndexTable={handleOpenIndexTable}
        parentPage={page}
        isDataPasted={isDataPasted}
      />
    ),
    [modelKey, hasWriteAccess, createMode, handleOpenForm, handleOpenIndexTable, page, isDataPasted]
  )
  const renderSmallField = useCallback(
    (config: FieldConfig) => (
      <SmallFields
        key={config?.name}
        hasWriteAccess={hasWriteAccess}
        createMode={createMode}
        config={config}
        onOpenForm={handleOpenForm}
      />
    ),
    [hasWriteAccess, createMode, handleOpenForm]
  )

  const onCopy = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      const fieldDataWithoutId = { ...fieldData }
      delete fieldDataWithoutId.id
      dispatch(setCopiedDataAction({ values: fieldDataWithoutId ?? {} }))
      localStorage.removeItem('formData')
      localStorage.setItem('formData', JSON.stringify(fieldDataWithoutId))
    },
    [fieldData, dispatch]
  )

  const onPaste = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setIsDataPasted(true)
  }, [])

  return (
    <CardWrapper
      style={{ width: card.width }}
      active={card.active}
      activeComponent={<CollapsedCard title={pageTitle} page={page} />}
    >
      <Card>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validationSchema={validationSchema}
          initialValues={{
            ...(createMode ? initialValues ?? {} : {}),
            ...(fieldData ?? {}),
            ...(isDataPasted ? copiedData : {})
          }}
          onSubmit={handleSubmitForm}
        >
          {({ isValid, isSubmitting, handleSubmit }) => (
            <StyledForm>
              <FormCardHeader
                page={page}
                card={card}
                disableActions={createMode || isSubmitting}
                disabledSubmit={!isValid || isSubmitting}
                onSubmit={handleSubmit}
                title={title}
                autoSave={<AutoSave debounceMs={300} />}
                showActions={false}
              />
              <FormWrapper>
                <CardTitle>
                  <Display size='sm'>{pageTitle}</Display>
                  <ButtonContainer>
                    <Button onClick={onCopy}>Copy data</Button>
                    <Button disabled={!copiedData || copiedData?.__type !== modelKey} onClick={onPaste}>
                      Paste data
                    </Button>
                  </ButtonContainer>
                </CardTitle>
                {!createMode && (!called || loading) && !fieldData ? (
                  <Spinner />
                ) : (
                  <Fields hasLargeFields={hasLargeFields}>
                    {hasLargeFields && <LargeFieldsColumn>{large.map(renderLargeField)}</LargeFieldsColumn>}
                    <SmallFieldsColumns>{small.map(renderSmallField)}</SmallFieldsColumns>
                  </Fields>
                )}
              </FormWrapper>
            </StyledForm>
          )}
        </Formik>
      </Card>
    </CardWrapper>
  )
}

export default SubformCard
