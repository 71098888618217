import { HASH_SUBFORM_DELIMITER } from '@/constants/url'
import config from '@/helpers/config'

export const addSubformToHash = (parent: string, subform: string) => {
    return `${parent}/${HASH_SUBFORM_DELIMITER}${subform}`
}

export const buildHash = (form: string, subforms: string[]) => {
    return [form, ...subforms].join(`/${HASH_SUBFORM_DELIMITER}`)
}
export const parseHash = (
    hash: string
): {
    form: string
    subforms: string[]
} => {
    const parts = hash.replace('#', '').split(`/${HASH_SUBFORM_DELIMITER}`)
    const form = parts[0]
    const other = parts.slice(1)
    return {
        form,
        subforms: other.map((part, idx) => {
            return buildHash(form, other.slice(0, idx + 1))
        })
    }
}

export const splitHash = (
    hash: string
): {
    form: string
    subforms: string[]
} => {
    const parts = hash.replace('#', '').split(`/${HASH_SUBFORM_DELIMITER}`)
    const form = parts[0]
    const other = parts.slice(1)
    return {
        form,
        subforms: other
    }
}

export const getParentHash = (hash: string) => {
    const parsed = parseHash(hash)
    return parsed.subforms.length > 1 ? parsed.subforms[parsed.subforms.length - 2] : parsed.form
}

export const getFieldDataPath = (hash: string) => {
    const parts = splitHash(hash)
    return parts.subforms.map((sf) => {
        const elements = sf.split('/')
        return {
            fieldName: elements[2],
            id: elements[1].split('-')[1]
        }
    })
}

export const getParams = (hash: string) => {
    const [, modelKey, fieldName, index] = hash.split('/')
    return { modelKey, fieldName, index }
}

export const getHashParams = (hash: string) => {
    const parts = splitHash(hash)
    return getParams(parts.subforms[parts.subforms.length - 1])
}

export const getAbsoluteUrl = (path: string) => {
    return path ? `${config.REACT_APP_DOMAIN_URL}/${path}` : ''
}
