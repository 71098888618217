import { schema } from '@/helpers/schema'
import { Field, FieldArrayLike, FieldObject, Model, ModelEnum, ModelType } from '@/client/schema'
import { getDefaultId } from '@/helpers/subforms'

function findFieldByType(fieldObject: FieldObject, newDefault: any): { type: string; model: Model } {
    let typeRef: { type: string; model: Model } = fieldObject.__refs[0]

    if ('inheritors' in typeRef.model && typeRef.model.inheritors) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-restricted-syntax
        for (const ref of typeRef.model.inheritors.__refs) {
            if (ref.$ref.__key === newDefault.type || ref.type === newDefault.type) {
                typeRef = { type: ref.$ref.__key, model: ref.$ref }
                break
            }
        }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const ref of fieldObject.__refs) {
        if (ref.model.__key === newDefault.type || ref.type === newDefault.type) {
            typeRef = ref
            break
        }
    }

    return typeRef
}

function enrichNestedFields(newDefault: any, typeRef: { type: string; model: Model }) {
    Object.keys(newDefault).forEach((key) => {
        const value = newDefault[key]
        const typeRefModel = typeRef.model as ModelType
        if (typeof value === 'object') {
            // eslint-disable-next-line no-use-before-define
            newDefault[key] = enrichDefault(typeRefModel.fields[key], value)
        }
    })

    return newDefault
}

function enrichDefaultObject(field: Field, defaultValue: any): any {
    let newDefault = { ...defaultValue, __field: field.__key }
    const fieldObject = field as FieldObject
    const typeRef = findFieldByType(fieldObject, newDefault)
    newDefault = enrichNestedFields(newDefault, typeRef)
    newDefault.__type = typeRef.model.__key

    return newDefault
}

function enrichDefaultArray(field: Field, defaultValue: any): any {
    const arrayLike = field as FieldArrayLike
    if (arrayLike.__kind === 'ArrayLikeOfScalar') {
        return field.default
    }

    const arrayNewDefault: Record<string, any>[] = []

    for (let i = 0; i < defaultValue.length; i += 1) {
        let newDefault = { ...defaultValue[i], __field: field.__key }
        const fieldObject = arrayLike.of as FieldObject
        const typeRef = findFieldByType(fieldObject, newDefault)
        newDefault = enrichNestedFields(newDefault, typeRef)
        newDefault.__type = typeRef.model.__key

        arrayNewDefault[i] = newDefault
    }

    return arrayNewDefault
}

function enrichDefault(field: Field, defaultValue: any): any {
    switch (field.type) {
        case 'Object':
            return enrichDefaultObject(field, defaultValue)
        case 'Set':
        case 'List':
            return enrichDefaultArray(field, defaultValue)
        default:
            return field.default
    }
}

type GetFieldInitialValue = (field: Field) => any
const getFieldInitialValues: GetFieldInitialValue = (field: Field) => {
    if (field.default) {
        return enrichDefault(field, field.default)
    }
    if (field.const) {
        return field.const
    }
    if (field.type === 'Boolean') {
        return false
    }
    if (field.type === 'Ref' && field.__kind === 'RefEnum') {
        const fieldEnum = (field.__ref as ModelEnum).members
        const options = Object.keys(fieldEnum)
        return options[0]
    }

    return undefined
}

const getInitialValuesByModelKey = (modelKey: string) => {
    const model = schema.models[modelKey] as ModelType
    const fieldsValidation = Object.keys(model.fields).reduce((accumulator, selector) => {
        if (selector === 'id') {
            return accumulator
        }
        return { ...accumulator, [selector]: getFieldInitialValues(model.fields[selector]) }
    }, {})

    return fieldsValidation
}

export const FORM_INITIAL_VALUES_BY_MODEL_KEY: Record<string, any> = Object.keys(schema.models).reduce(
    (accumulator, modelKey) => {
        const model = schema.models[modelKey]
        if (model.type === 'Type' || model.type === 'ObjectType' || model.type === 'Class') {
            return { ...accumulator, [modelKey]: getInitialValuesByModelKey(modelKey) }
        }
        return accumulator
    },
    {}
)
