import { createReducer } from '@reduxjs/toolkit'
import { setCopiedDataAction } from '../actions/copiedData'

export type CopiedDataState = {
    data?: Record<string, any>
}

export type CopiedDataReducerState = CopiedDataState

const initialState: CopiedDataReducerState = {}

export const copiedDataReducer = createReducer(initialState, (builder) => {
    builder.addCase(setCopiedDataAction, (state, { payload: { values } }) => {
        state.data = values
    })
})
