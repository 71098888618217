import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Formik, FormikHelpers } from 'formik'
import { useAlert } from '@blaumaus/react-alert'

import { Card, CardConfig, CardTitle } from '@/components/regions/Card'
import CollapsedCard from '@/components/regions/CollapsedCard'
import { Display } from '@/components/typography/Display'
import { TextPlaceholder } from '@/components/blocks/TextPlaceholder'
import Spinner from '@/components/controls/Spinner'
import { CardWrapper } from '@/components/regions'

import { FORM_INITIAL_VALUES_BY_MODEL_KEY } from '@/helpers/model/form/initialValues'
import { FORM_VALIDATION_SCHEMA_BY_MODEL_KEY } from '@/helpers/model/form/validation'
import { FieldConfig, FORM_FIELDS_BY_MODEL_KEY } from '@/helpers/model/form/fields'
import { TITLE_GETTER_BY_MODEL_KEY } from '@/helpers/model/title'
import { FilterOption } from '@/helpers/model/table/filterAndSort'
import { getPathByModelKey } from '@/helpers/model/menu'
import { getConfigByPathname, useCardsHelper } from '@/hooks/useCardsHelper'
import { useMountRequest } from '@/hooks/useMountRequest'
import { useCognitoUser } from '@/hooks/useCognitoUser'

import { useAppDispatch } from '@/redux/store'
import { PageConfig, resetPagesAction } from '@/redux/actions/common/ui'
import { setFilterTableAction } from '@/redux/actions/table'
import { requestFormDataAction, requestCreateEntityAction, requestUpdateEntityAction } from '@/redux/actions/form'
import { selectForm } from '@/redux/selectors/form'
import { selectPages } from '@/redux/selectors/common/ui'

import FormCardHeader from './components/FormCardHeader'
import SmallFields from './components/SmallFields'
import LargeFields from './components/LargeFields'
import SearchCard from '../SearchCard'
import { StyledForm, FormWrapper, Fields, LargeFieldsColumn, SmallFieldsColumns } from './styles'
import { removeIdFromItems } from './utils'

export type FormCardProps = { page: PageConfig; card: CardConfig }
const FormCard: React.FC<FormCardProps> = ({ page, card }) => {
  const alert = useAlert()
  const dispatch = useAppDispatch()
  const { hasWriteAccess } = useCognitoUser()
  const { onOpenForm, onOpenIndex, onClose } = useCardsHelper()
  const pages = useSelector(selectPages)

  const [activeSearch, setActiveSearch] = useState(false)
  const { modelKey, id, path, model } = useMemo(() => getConfigByPathname(page.path), [page.path])
  const { data, loading, called } = useSelector(selectForm(id!))
  const createMode = useMemo(() => id === 'new', [id])
  const initialValues = useMemo(() => FORM_INITIAL_VALUES_BY_MODEL_KEY[modelKey], [modelKey])
  const validationSchema = useMemo(() => FORM_VALIDATION_SCHEMA_BY_MODEL_KEY[modelKey], [modelKey])
  const { large, small } = useMemo(() => FORM_FIELDS_BY_MODEL_KEY[modelKey], [modelKey])
  const hasLargeFields = useMemo(() => large.length > 0, [large])
  const hasBundleField = useMemo(() => large.some((field) => field?.name === 'bundle'), [large])
  const pageTitle = useMemo(() => {
    if (createMode) {
      return 'Create'
    }
    if (loading || !called || data === null) {
      return <TextPlaceholder />
    }
    const { key, getValue } = TITLE_GETTER_BY_MODEL_KEY[modelKey]
    return getValue(data?.[key])
  }, [modelKey, createMode, data, loading, called])

  useMountRequest(() => {
    if (!called && !loading && !createMode) {
      dispatch(requestFormDataAction({ modelKey, id: id! }))
    }
  })

  useEffect(() => {
    if (pages.length > 1 && pages[1].path === page.path && hasBundleField) {
      const newPages = pages.map((pageConfig, index) => {
        if (index === 1) {
          return { ...pageConfig, expanded: true, isFullWidthWhenExpanded: true }
        }
        return pageConfig
      })
      dispatch(resetPagesAction(newPages))
    }
  }, [dispatch, hasBundleField])

  useEffect(() => {
    if (called && !loading && data === null) {
      onClose(page.path)
      alert.error(`${model.title} not found`)
    }
  }, [called, loading, data, model, page.path, alert, onClose])

  const handleSubmitForm = useCallback(
    async (values: any, actions: FormikHelpers<any>) => {
      try {
        if (createMode) {
          const { data: createData } = await dispatch(
            requestCreateEntityAction({ modelKey, values: removeIdFromItems(values) })
          ).unwrap()
          onClose(page.path)
          onOpenForm(`${path.model}/${createData.id}`)
        } else {
          await dispatch(requestUpdateEntityAction({ modelKey, values: removeIdFromItems(values) })).unwrap()
        }
        actions.setSubmitting(false)
        alert.show('Saved')
      } catch (e) {
        alert.error((e as any).message)
      }
    },
    [modelKey, path, page.path, createMode, alert, dispatch, onClose, onOpenForm]
  )
  const handleOpenSearch = useCallback(() => setActiveSearch(true), [])
  const handleCloseSearch = useCallback(() => setActiveSearch(false), [])

  const handleOpenForm = useCallback(
    (formModelKey: string, formId: string, expanded: boolean) => {
      const modelPath = getPathByModelKey(formModelKey)
      onOpenForm(`${modelPath}/${formId}`, expanded, page.path)
    },
    [page.path, onOpenForm]
  )
  const handleOpenIndexTable = useCallback(
    (indexModelKey: string, filter: FilterOption) => {
      dispatch(
        setFilterTableAction({
          modelKey: indexModelKey,
          data: { key: filter.value, parameter: filter.parameterName, value: data }
        })
      )
      onOpenIndex(`${path.part}/${indexModelKey}`, page.path)
    },
    [path.part, page, data, dispatch, onOpenIndex]
  )

  const renderLargeField = useCallback(
    (config: FieldConfig) => (
      <LargeFields
        key={config?.name}
        modelKey={modelKey}
        hasWriteAccess={hasWriteAccess}
        createMode={createMode}
        config={config}
        onOpenForm={handleOpenForm}
        onOpenIndexTable={handleOpenIndexTable}
        parentPage={page}
      />
    ),
    [modelKey, hasWriteAccess, createMode, handleOpenForm, handleOpenIndexTable, page]
  )
  const renderSmallField = useCallback(
    (config: FieldConfig) => (
      <SmallFields
        key={config?.name}
        hasWriteAccess={hasWriteAccess}
        createMode={createMode}
        config={config}
        onOpenForm={handleOpenForm}
      />
    ),
    [hasWriteAccess, createMode, handleOpenForm]
  )

  if (activeSearch) {
    return <SearchCard page={page} onClose={handleCloseSearch} />
  }

  return (
    <CardWrapper
      style={{ width: page.isFullWidthWhenExpanded ? '100vw' : card.width }}
      active={page.isFullWidthWhenExpanded ? card.active && page.expanded : card.active}
      activeComponent={<CollapsedCard title={pageTitle} page={page} openExpanded={page.isFullWidthWhenExpanded} />}
    >
      <Card>
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validationSchema={validationSchema}
          initialValues={(data || initialValues) as any}
          onSubmit={handleSubmitForm}
        >
          {({ dirty, isValid, isSubmitting, handleSubmit }) => (
            <StyledForm>
              <FormCardHeader
                page={page}
                card={card}
                disableActions={createMode || isSubmitting}
                disabledSubmit={!dirty || !isValid || isSubmitting}
                onSubmit={handleSubmit}
                onOpenSearch={handleOpenSearch}
                dirty={dirty}
                isTwoRows={hasBundleField}
              />
              <FormWrapper $isFullWidth={page.isFullWidthWhenExpanded}>
                {!hasBundleField && (
                  <CardTitle>
                    <Display size='sm'>{pageTitle}</Display>
                  </CardTitle>
                )}
                {!createMode && (!called || loading) ? (
                  <Spinner />
                ) : (
                  <Fields hasLargeFields={hasLargeFields} hasBundleField={hasBundleField}>
                    {hasLargeFields && (
                      <LargeFieldsColumn $hasBundleField={hasBundleField}>
                        {large.map(renderLargeField)}
                      </LargeFieldsColumn>
                    )}
                    <SmallFieldsColumns>{small.map(renderSmallField)}</SmallFieldsColumns>
                  </Fields>
                )}
              </FormWrapper>
            </StyledForm>
          )}
        </Formik>
      </Card>
    </CardWrapper>
  )
}

export default FormCard
