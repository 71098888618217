import styled from 'styled-components'

export const SidebarMenuContainer = styled.div<{ $isHidden: boolean; $isCollapsed: boolean }>`
    display: ${({ $isHidden }) => ($isHidden ? 'none' : 'block')};
    min-width: ${({ $isCollapsed }) => ($isCollapsed ? '50px' : '285px')};
    border-right: 1px solid ${({ theme }) => theme.colors.gray['200']};
`

export const MenuBlock = styled.div`
    padding: 8px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray['200']};

    &:last-child {
        border-bottom: none;
    }
`

export const CollapsedMenuBlock = styled.div`
    writing-mode: tb-rl;
    margin: 60px 15px;
`

export const CollapsedButtonWrapper = styled.div<{ $isCollapsed: boolean }>`
    position: absolute;
    z-index: 999;
    left: ${({ $isCollapsed }) => ($isCollapsed ? '10px' : '245px')};
    top: 16px;
`
