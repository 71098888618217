import React, { useCallback } from 'react'

import InputField from '@/components/fields/InputField'
import DatetimeField from '@/components/fields/DatetimeField'
import CheckboxField from '@/components/fields/CheckboxField'
import SelectField from '@/components/fields/SelectField'
import AsyncSelectField from '@/components/fields/AsyncSelectField'

import { FieldConfig } from '@/helpers/model/form/fields'
import ColorField from '@/components/fields/ColorField'
import TextareaField from '@/components/fields/TextareaField'

type Props = {
  createMode?: boolean
  hasWriteAccess?: boolean
  config: FieldConfig
  onSelect?: (config: FieldConfig, value: string) => void
  onOpenForm?: (formModelKey: string, id: string, expanded: boolean) => void
}
const SmallFields: React.FC<Props> = ({ createMode, hasWriteAccess, config, onOpenForm, onSelect }) => {
  const handleOpenForm = useCallback(
    (formModelKey: string) => (id: string, expanded: boolean) => onOpenForm && onOpenForm(formModelKey, id, expanded),
    [onOpenForm]
  )

  if (!config || config.name === 'id') {
    return null
  }
  const label = config.hideLabel ? undefined : config.label || config.name.toUpperCase()
  const disabled =
    !hasWriteAccess || config.disabled?.all || (config.disabled?.edit && !createMode) || config.disabled?.const
  if (config.type === 'input' && config.inputType === 'text-pre') {
    return (
      <TextareaField
        name={config.name}
        label={label}
        disabled={disabled}
        placeholder={`Enter ${config.inputType || 'value'}...`}
      />
    )
  }
  if (config.type === 'input') {
    return (
      <InputField
        name={config.name}
        label={label}
        disabled={disabled}
        type={config.inputType}
        placeholder={`Enter ${config.inputType || 'value'}...`}
      />
    )
  }
  if (config.type === 'color') {
    return <ColorField name={config.name} label={label} disabled={disabled} placeholder='Enter hex color...' />
  }
  if (config.type === 'timestamp') {
    return (
      <DatetimeField
        isMs={config.isMs}
        time
        name={config.name}
        label={label}
        disabled={disabled}
        placeholder='Select date and time...'
      />
    )
  }
  if (config.type === 'checkbox') {
    return <CheckboxField name={config.name} label={label} disabled={disabled} />
  }
  if (config.type === 'select') {
    return (
      <SelectField
        name={config.name}
        label={label}
        disabled={disabled}
        optional={config.optional}
        options={config.options!}
        onSelect={(value) => onSelect && onSelect(config, value)}
      />
    )
  }
  if (config.type === 'async-select') {
    return (
      <AsyncSelectField
        name={config.name}
        modelKey={config.modelKey}
        label={label}
        disabled={disabled}
        state={config.state}
        previewFields={config.previewFields}
        onOpen={handleOpenForm(config.modelKey!)}
      />
    )
  }
  return null
}

SmallFields.defaultProps = {
  createMode: undefined,
  hasWriteAccess: undefined,
  onSelect: undefined,
  onOpenForm: undefined
}

export default SmallFields
