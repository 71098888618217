import React, { useCallback, FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import Menu from '@/components/controls/Menu'
import Account from '@/components/blocks/Account'
import Tooltip from '@/components/blocks/Tooltip'
import Button from '@/components/controls/Button'
import Minimize from '@/components/icons/cardHeader/Minimize'
import Maximize from '@/components/icons/cardHeader/Maximize'
// import MenuItem from '@/components/controls/Menu/components/MenuItem'
// import Notification from '@/components/icons/menu/Notification'

// import { NOTIFICATIONS_URL } from '@/constants/routes'
import { MENU_CONFIG } from '@/helpers/model/menu'
import { useCardsHelper } from '@/hooks/useCardsHelper'

import { useAppDispatch } from '@/redux/store'
import { setMenuCollapsedAction } from '@/redux/actions/common/menu'
import { selectMenuState } from '@/redux/selectors/common/menu'

import { CollapsedButtonWrapper, CollapsedMenuBlock, MenuBlock, SidebarMenuContainer } from './styles'

type Props = {
  isCollapsed: boolean
  onCollapse: () => void
}

const SidebarMenu: FC<Props> = ({ isCollapsed, onCollapse }) => {
  const dispatch = useAppDispatch()
  const { onOpenTable, activeCards } = useCardsHelper()
  const { collapsedPaths } = useSelector(selectMenuState)

  const isMenuHidden = useMemo(
    () => activeCards[1]?.page?.isFullWidthWhenExpanded && activeCards[1]?.page?.expanded,
    [activeCards[1]?.page?.isFullWidthWhenExpanded, activeCards[1]?.page?.expanded]
  )
  const handleToggleMenu = useCallback(
    (path: string) => {
      dispatch(setMenuCollapsedAction(path))
    },
    [dispatch]
  )

  return (
    <SidebarMenuContainer $isHidden={!!isMenuHidden} $isCollapsed={isCollapsed}>
      <CollapsedButtonWrapper $isCollapsed={isCollapsed}>
        <Tooltip position='bottom' description={!isCollapsed ? 'Collapse' : 'Expand'}>
          <Button type='button' icon='icon' size='sm' styleType='tertiary' variant='gray' onClick={onCollapse}>
            {!isCollapsed ? <Minimize /> : <Maximize />}
          </Button>
        </Tooltip>
      </CollapsedButtonWrapper>
      {isCollapsed ? (
        <CollapsedMenuBlock>Menu</CollapsedMenuBlock>
      ) : (
        <>
          <MenuBlock>
            <Account />
            {/* <MenuItem
          type='system'
          icon={<Notification />}
          title='Notifications'
          path={NOTIFICATIONS_URL}
          onSelect={onOpenTable}
        /> */}
          </MenuBlock>
          <MenuBlock>
            {MENU_CONFIG.map(({ variant, config }) => (
              <Menu
                key={config.path}
                variant={variant}
                config={config}
                collapsed={collapsedPaths.includes(config.path)}
                onCollapse={handleToggleMenu}
                onSelect={onOpenTable}
              />
            ))}
          </MenuBlock>
        </>
      )}
    </SidebarMenuContainer>
  )
}

export default SidebarMenu
