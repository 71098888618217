import { Field, FieldArrayLike, FieldIndex, FieldRef, ModelEnum, ModelType, TypeRef } from '@/client/schema'
import { DisableOption, FieldConfig, FieldState } from '@/helpers/model/form/fields'
import { getDataCellValues } from '@/helpers/model/table/columns'
import { SelectOption } from '@/components/controls/Select'
import { _getFormFieldName } from '@/helpers/model/form/_getFormFieldName'

export const _getInputConfig = (field: Field, name: string, disabled: DisableOption) => {
    const config = { name, disabled, type: 'input', deprecated: field.deprecated } as FieldConfig

    if (!config) {
        return undefined
    }

    switch (field.type) {
        case 'String':
        case 'ID':
            config.inputType = 'text'
            if (field.__model?.__key === 'formulaTextPre') {
                config.inputType = 'text-pre'
            }
            break
        case 'Int':
        case 'Float':
            config.inputType = 'number'
            break
        case 'Phone':
            config.inputType = 'tel'
            break
        case 'Email':
            config.inputType = 'email'
            break
        case 'URL':
            config.inputType = 'url'
            break
        default:
            break
    }

    return config
}

const _getPreviewFields = (field: TypeRef | FieldArrayLike | FieldIndex) => {
    if (field.__previewFields) {
        return field.__previewFields.map((fields) =>
            fields.map((previewField) => ({
                name: _getFormFieldName(previewField),
                config: getDataCellValues(previewField)
            }))
        )
    }
    return undefined
}

const _getListArrayLikeOfScalarConfig = (field: FieldArrayLike, name: string, disabled: DisableOption, _func: any) => {
    const fieldRef = field.of as FieldRef
    const refConfig = _func(fieldRef as Field)!

    return {
        ...refConfig,
        list: true,
        name,
        disabled,
        large: true,
        deprecated: field.deprecated
    }
}

const _getListArrayLikeOfObjectConfig = (field: FieldArrayLike, name: string, disabled: DisableOption, _func: any) => {
    const fieldRef = field.of as FieldRef
    const refConfig = _func(fieldRef as Field)

    return {
        ...refConfig,
        list: true,
        modelKey: 'object',
        name,
        disabled,
        deprecated: field.deprecated
    }
}

const _getListArrayLikeOfRefTypeConfig = (field: FieldArrayLike, name: string, disabled: DisableOption) => {
    const fieldRef = field.of as FieldRef
    const modelKey = (fieldRef.__ref as ModelType).__key
    const config: FieldConfig = {
        name,
        modelKey,
        disabled,
        type: 'relation',
        large: true,
        previewFields: _getPreviewFields(field),
        deprecated: field.deprecated
    }

    return config
}

export const _getListConfig = (field: FieldArrayLike, name: string, disabled: DisableOption, _func: any) => {
    switch (field.__kind) {
        case 'ArrayLikeOfScalar':
            return _getListArrayLikeOfScalarConfig(field, name, disabled, _func)
        case 'ArrayLikeOfObject':
            return _getListArrayLikeOfObjectConfig(field, name, disabled, _func)
        case 'ArrayLikeOfRefType':
            return _getListArrayLikeOfRefTypeConfig(field, name, disabled)
        default:
            throw new Error('Invalid field kind')
    }
}

export const _getIndexConfig = (field: FieldIndex, name: string, disabled: DisableOption) => {
    const {
        fields: { state },
        __key: modelKey
    } = field.__ref.__model as ModelType

    const config: FieldConfig = {
        name,
        modelKey,
        disabled,
        type: 'index',
        large: true,
        previewFields: _getPreviewFields(field),
        deprecated: field.deprecated
    }

    if (state) {
        const { members } = (state as FieldRef).__ref as ModelEnum
        config.state = members as FieldState
    }

    return config
}

const _getRefEnumConfig = (field: FieldRef, name: string, disabled: DisableOption) => {
    const fieldEnum = (field.__ref as ModelEnum).members
    const options = Object.keys(fieldEnum).reduce(
        (accumulator, item) => [...accumulator, { value: item, name: item, color: fieldEnum[item].color }],
        [] as SelectOption[]
    )
    return { name, disabled, type: 'select', options, deprecated: field.deprecated } as FieldConfig
}

const _getRefTypeConfig = (field: FieldRef, name: string, disabled: DisableOption) => {
    const {
        fields: { state },
        __key: modelKey
    } = field.__ref as ModelType
    const config: FieldConfig = {
        name,
        modelKey,
        disabled,
        type: 'async-select',
        previewFields: _getPreviewFields(field),
        deprecated: field.deprecated
    }

    if (state) {
        const { members } = (state as FieldRef).__ref as ModelEnum
        config.state = members as FieldState
    }

    return config
}

export const _getRefConfig = (field: FieldRef, name: string, disabled: DisableOption) => {
    switch (field.__kind) {
        case 'RefEnum':
            return _getRefEnumConfig(field, name, disabled)
        case 'RefType':
            return _getRefTypeConfig(field, name, disabled)
        default:
            throw new Error('Invalid field kind')
    }
}

const _getSetArrayLikeOfRefEnumConfig = (field: FieldArrayLike, name: string, disabled: DisableOption) => {
    const fieldRef = field.of as FieldRef
    const fieldEnum = (fieldRef.__ref as ModelEnum).members
    const options = Object.keys(fieldEnum).reduce(
        (accumulator, item) => [...accumulator, { value: item, name: item, color: fieldEnum[item].color }],
        [] as SelectOption[]
    )
    return { name, disabled, type: 'enum-select', options, large: true, deprecated: field.deprecated } as FieldConfig
}

const _getSetDefaultConfig = (field: FieldArrayLike, name: string, disabled: DisableOption) => {
    const fieldRef = field.of as FieldRef
    const modelKey = (fieldRef.__ref as ModelType).__key
    const config: FieldConfig = {
        name,
        modelKey,
        disabled,
        type: 'relation',
        large: true,
        previewFields: _getPreviewFields(field),
        deprecated: field.deprecated
    }

    return config
}

export const _getSetConfig = (field: FieldArrayLike, name: string, disabled: DisableOption) => {
    switch (field.__kind) {
        case 'ArrayLikeOfRefEnum':
            return _getSetArrayLikeOfRefEnumConfig(field, name, disabled)
        default:
            return _getSetDefaultConfig(field, name, disabled)
    }
}
