import React, { useMemo, useState, useCallback } from 'react'
import { Outlet } from 'react-router-dom'

import SidebarMenu from '@/components/regions/SidebarMenu'
import { useCardsHelper } from '@/hooks/useCardsHelper'
import { PageContent, PageLayout } from './styles'

const Page: React.FC = () => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false)
  const { activeCards } = useCardsHelper()

  const isMenuHidden = useMemo(
    () => activeCards[1]?.page?.isFullWidthWhenExpanded && activeCards[1]?.page?.expanded,
    [activeCards[1]?.page?.isFullWidthWhenExpanded, activeCards[1]?.page?.expanded]
  )

  const onCollapse = useCallback(() => {
    setIsMenuCollapsed((prev) => !prev)
  }, [setIsMenuCollapsed])

  const pageWidth = useMemo(() => {
    const menuWidth = isMenuCollapsed ? 50 : 285
    return isMenuHidden ? '100vw' : `calc(100vw - ${menuWidth}px)`
  }, [isMenuCollapsed, isMenuHidden])

  return (
    <PageLayout>
      <SidebarMenu isCollapsed={isMenuCollapsed} onCollapse={onCollapse} />
      <PageContent $width={pageWidth}>
        <Outlet />
      </PageContent>
    </PageLayout>
  )
}

export default Page
