import { Field, Model, ModelType } from '@/client/schema'
import { SelectOption } from '@/components/controls/Select'
import { DataCellValues } from '@/components/controls/Table/components/DataCell'
import { _getFormFieldName } from '@/helpers/model/form/_getFormFieldName'
import { _getFieldConfig } from '@/helpers/model/form/_getFieldConfig'
import { schema } from '@/helpers/schema'

export const getFormFieldName = _getFormFieldName

type FieldType =
    | 'input'
    | 'color'
    | 'timestamp'
    | 'json'
    | 'rich-text'
    | 'checkbox'
    | 'select'
    | 'async-select'
    | 'enum-select'
    | 'index'
    | 'relation'
    | 'image'
    | 'custom'
    | 'object'
    | 'class'
    | 'bundle'

export type DisableOption = { all?: boolean; edit?: boolean; const?: boolean }

type DefaultFieldConfig = {
    name: string
    type: FieldType
    label?: string
    hideLabel?: boolean
    large?: boolean
    disabled?: DisableOption
    deprecated?: boolean
}

type InputFieldConfig = {
    list?: boolean
    inputType?:
        | 'text'
        | 'number'
        | 'password'
        | 'email'
        | 'tel'
        | 'url'
        | 'date'
        | 'time'
        | 'datetime-local'
        | 'month'
        | 'week'
        | 'color'
        | 'search'
        | 'file'
        | 'hidden'
        | 'image'
        | 'range'
        | 'reset'
        | 'submit'
        | 'text-pre'
}

type ImageFieldConfig = {
    modalPath?: string
    path?: string
    extension?: string
    size?: { width: number; height: number }
    previewSize?: 'Small' | 'Medium' | 'Large'
    pathWithoutSize?: boolean
    list?: boolean
}

type BundleFieldConfig = {
    path?: string
    extension?: string
    viewerLink?: string
}

type MarkdownFieldConfig = {
    imagesModalPath?: string
    imagesPath?: string
    imagesExtension?: string
}

type SelectFieldConfig = { optional?: boolean; options?: SelectOption[] }

export type StateConfig = { color: string; finaly?: boolean }
export type PreviewFields = { name: string; config: DataCellValues }[][]
export type FieldState = Record<string, StateConfig>
type AsyncSelectFieldConfig = {
    modelKey?: string
    previewFields?: PreviewFields
    state?: FieldState
}
type ObjectFieldConfig = {
    modelKey?: string
    refs?: {
        type: string
        model: Model
    }[]
    previewFields?: PreviewFields
    state?: FieldState
    field?: Field
    list?: boolean
}
type ClassFieldConfig = {
    modelKey?: string
    ref?: Model
    previewFields?: PreviewFields
    state?: FieldState
    field?: Field
    list?: boolean
}
type DateFieldConfig = {
    isMs?: boolean
}
type FieldConfigType = DefaultFieldConfig &
    InputFieldConfig &
    ImageFieldConfig &
    SelectFieldConfig &
    AsyncSelectFieldConfig &
    ObjectFieldConfig &
    ClassFieldConfig &
    MarkdownFieldConfig &
    BundleFieldConfig &
    DateFieldConfig

export type FieldConfig = FieldConfigType | undefined

export const getFieldConfig = _getFieldConfig

type ModelFields = { small: FieldConfig[]; large: FieldConfig[] }

const _getFieldsByModelKey = (modelKey: string): ModelFields => {
    const model = schema.models[modelKey] as ModelType
    return Object.keys(model.fields).reduce(
        (accumulator, selector) => {
            const config = _getFieldConfig(model.fields[selector])
            if (!config) {
                return accumulator
            }
            if (config?.large) {
                return { ...accumulator, large: [...accumulator.large, config] }
            }
            return { ...accumulator, small: [...accumulator.small, config] }
        },
        { small: [], large: [] } as ModelFields
    )
}

const _getFieldByModel = (types: string[]): Record<string, ModelFields> => {
    return Object.keys(schema.models).reduce((accumulator, modelKey) => {
        const model = schema.models[modelKey]
        if (types.includes(model.type)) {
            return { ...accumulator, [modelKey]: _getFieldsByModelKey(modelKey) }
        }
        return accumulator
    }, {})
}

export const FORM_FIELDS_BY_MODEL_KEY: Record<string, ModelFields> = _getFieldByModel([
    'Type',
    'ObjectType',
    'Class',
    'Inheritor'
])
export const SUBFORM_FIELDS_BY_MODEL_KEY: Record<string, ModelFields> = _getFieldByModel([
    'Type',
    'ObjectType',
    'Class'
])
