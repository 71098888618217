import { createAction } from '@reduxjs/toolkit'

export const COPIED_DATA_ACTION = 'COPIED_DATA'
export const SET_COPIED_DATA_ACTION = `${COPIED_DATA_ACTION}/SET`

export type CopiedDataVariables = {
    values?: Record<string, any>
}

export const setCopiedDataAction = createAction<CopiedDataVariables>(SET_COPIED_DATA_ACTION)
