import React, { useCallback, useMemo, useEffect } from 'react'
import { useAppDispatch } from '@/redux/store'
import { setCopiedDataAction } from '@/redux/actions/copiedData'

import Modals from '@/modals'
import { ActivePage, useCardsHelper } from '@/hooks/useCardsHelper'

import TableCard from '../TableCard'
import FormCard from '../FormCard'
import SubformCard from '../SubformCard'
import Subscription from '../Subscription'

const CardsPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { modelKey, activeCards } = useCardsHelper()

  const isHiddenTableCard = useMemo(
    () => activeCards[1]?.page?.isFullWidthWhenExpanded && activeCards[1]?.page?.expanded,
    [activeCards[1]?.page?.isFullWidthWhenExpanded, activeCards[1]?.page?.expanded]
  )
  const expandable = useMemo(() => activeCards.length > 1, [activeCards.length])
  const large = useMemo(() => activeCards.length === 1 || activeCards.some(({ page }) => page.expanded), [activeCards])

  useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key === 'formData' && event.newValue) {
        const updatedData = JSON.parse(event.newValue)
        dispatch(setCopiedDataAction({ values: updatedData }))
      }
    }
    window.addEventListener('storage', listener)
    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [dispatch])

  const renderCard = useCallback(
    ({ page, active, width }: ActivePage) => {
      const card = { width, active, expandable, large }
      if (page.type === 'table' || page.type === 'index') {
        return <TableCard key={page.path} page={page} card={card} isHidden={isHiddenTableCard} />
      }
      if (page.type === 'form') {
        return <FormCard key={page.path} page={page} card={card} />
      }
      if (page.type === 'subform') {
        return <SubformCard key={page.path} page={page} card={card} />
      }
      return null
    },
    [expandable, large, isHiddenTableCard]
  )

  return (
    <>
      {activeCards.map(renderCard)}
      {modelKey && <Subscription modelKey={modelKey} />}
      <Modals />
    </>
  )
}

export default CardsPage
